import React from "react";
import { graphql } from "gatsby";

import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line

// Components
import { PageMeta } from "../components/pageMeta";
import BlockZone from "~blockZone";
import Layout from "../components/layout";

const PageTemplate = ({ data: { page } }) => {
  const { showForm, showTestimonials, meta, pageBlocks } = page || {};

  return (
    <Layout showForm={showForm} showTestimonials={showTestimonials}>
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      showForm
      showTestimonials
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
